body {
  background-color: #FCFCFC;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
  font-family: "Plus Jakarta Sans", helvetica, arial, sans-serif !important;
}

#root {
  min-height: 100vh;
}

h1, h1, h3, h4 {
  font-weight: 700!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ReactCollapse--collapse {
  transition: height 300ms;
}

.Masked-Input-input {
  width: 100%;
  min-height: 40px;
  border-radius: 6px;
  /*font-family: "Public Sans";*/
  font-size: 14px;
  /*color: #25252D;*/
  border: 1px solid #D8D8DF;
  background-color: #FFF;
  padding-left: 12px;
  padding-right: 12px;
  outline: 0;
  text-overflow: ellipsis;
  color: rgb(37, 37, 42);
}

.Masked-Input-input:hover {
  border: 1px solid #aaaab1;
}

.Masked-Input-input:focus {
  border: 2px solid #2c92d5;
}

.Joy-error .Masked-Input-input {
  border: 2px solid rgba(211, 35, 47, 0.51);
  color: #D3232F
}

.Joy-error .Masked-Input-input:focus {
  border: 2px solid rgb(211, 35, 47);
}

.Masked-Input-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.7; /* Firefox */
}

.ck-editor__editable {
  min-height: 300px;
}

div.notistack-MuiContent.notistack-MuiContent-success {
  background-color: #39a779!important;
}

ol li {
  color: #25252d;
}

li.MuiAutocomplete-option.Joy-focused[aria-selected="true"]:hover {
  background-color: #F0F8FD!important;
}
li.MuiAutocomplete-option[aria-selected="true"] {
  color: #3075B8!important;
  font-weight: 600!important;
  background-color: white!important;
}
li.MuiAutocomplete-option[aria-selected="true"]::before{
  content:url('auto-check.svg');
  position:absolute;
  left:10px;
}

li.MuiAutocomplete-option.Joy-focused {
  color: #3075B8!important;
  background-color: #F0F8FD!important;
}

li.MuiAutocomplete-option {
  padding-left: 31px;
}

@media screen and (max-width: 900px) {
  #jsd-widget {
    display: none;
  }
}

div.react-datepicker-popper {
  z-index: 2;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.multi_ellipsis {
  display: -webkit-box;
  /*max-width: 200px;*/
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

a {
  color: #3075B8;
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
}